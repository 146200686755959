import "./../css/app.scss";
import "./../css/editor-style.scss";

// import { initMediumMenu } from './global/sticky-menu';
// import { initMobileMenu } from './global/mobile-menu';

// initMediumMenu();
// initMobileMenu();

const handleMobileMenu = () => {
  const $mobileModal = document.querySelector("[data-offcanvas-mobile-menu]");

  var x = window.matchMedia("(min-width: 991px)");

  if ($mobileModal) {
    $mobileModal.showModal();

    x.addEventListener("change", function () {
      if (x.matches) {
        $mobileModal.close();
      }
    });
  }
};

const $mobileModalButton = document.querySelector("[data-open-mobile-menu]");
if ($mobileModalButton) {
  $mobileModalButton.addEventListener("click", handleMobileMenu);
}

const handleLazyLoadArticleImg = () => {

  function handleIntersection(entries) {
    entries.map((entry) => {
      if (entry.isIntersecting) {
        entry.target.style.setProperty("--article-img", `url('${entry.target.dataset.lazyArticleImg}')`);
        observer.unobserve(entry.target);
      }
    });
  }

  const images = document.querySelectorAll('[data-lazy-article-img]');
  const observer = new IntersectionObserver(handleIntersection, { rootMargin: "100px" });
  images.forEach(image => observer.observe(image));
}
handleLazyLoadArticleImg();





